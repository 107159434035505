import React from 'react'
import './service-carousel.css'
import './animations.css'

const BACKGROUND_COLOR = 'white'
const TEXT_COLOR = 'hsl(120, 90%, 10%)'

export class ServiceCarousel extends React.Component {
    timerIDs = []
    props
    state
    animationDurationStyle = {}

    static defaultProps = {
        height: 228,
        width: 640,
        services: ['real interaction', 'imagination'],
        animationDuration: 400,
    }

    constructor(props) {
        super(props)
        this.state = {
            currentService: props.services[0],
            nextService: props.services[1],
            showingNextService: false,
        }

        this.animationDurationStyle = {animationDuration: `${props.animationDuration}ms`}
    }

    componentDidMount() {
        this.timerIDs.push(
            setInterval(() => {
                this.showNextService()
            }, 8000)
        )
    }

    componentWillUnmount() {
        this.timerIDs.forEach(timer => clearInterval(timer))
    }

    showNextService() {
        const indexOfCurrentService = this.props.services.indexOf(
            this.state.currentService
        )
        const nextService = this.props.services[indexOfCurrentService + 1] || this.props.services[0]

        this.setState({
            nextService: nextService,
            showingNextService: true,
        })
        this.timerIDs.push(
            setTimeout(() => {
                this.setState({showingNextService: false})
                this.setState({currentService: nextService})
            }, this.props.animationDuration)
        )
    }

    render() {
        const {height, width} = this.props
        const state = this.state
        const animationDurationStyle = this.animationDurationStyle

        return (
            <div
                style={{
                    background: BACKGROUND_COLOR,
                    width: width,
                    height: height,
                    color: TEXT_COLOR,
                    fontSize: 24,
                    fontWeight: 600,
                    padding: '30px 0',
                }}
            >
                <h1 style={{display: 'flex'}}>
                    <span style={{marginRight: '0.8rem', flexShrink: 0}}>Design for</span>
                    <span style={{overflow: 'hidden', flexShrink: 0}}>
                    {state.showingNextService && <span className='animatable appearing-from-the-left'
                                                       style={animationDurationStyle}
                    >{state.nextService}</span>}
                        <span className={`animatable ${state.showingNextService ? 'disappearing' : ''}`}
                              style={animationDurationStyle}
                        >{state.currentService}</span>
                </span>
                </h1>
            </div>
        )
    }
}
