import React from 'react'
import './detailed-story.css'
import './animations.css'

export class DetailedStory extends React.Component {
    props

    static defaultProps = {
        flow: false
    }

    constructor(props) {
        super(props)
    }

    render() {
        const {children, flow} = this.props

        return (
            <span>
                {flow ?
                    <span className='appearing-from-the-left'>{children} </span> :
                    <div className='appearing-from-the-top space-below'>{children}</div>}
            </span>
        )
    }
}
