import React from 'react'
import {DetailedStory} from './detailed-story'

export const LINK_COLOR = 'hsl(12, 90%, 60%)'

// When building for production, the Uglify Webpack plugin shortens the class names of both
// DetailedStory and LearnMore to the same, new name. The breaks the application. To fix this,
// LearnMore and ExpandingStory need to be in the same js file.
export class LearnMore extends React.Component {
    props

    static defaultProps = {
        toggleExpandStory: () => { // This function is provided by ExpandingStory
        },
        payload: 0
    }

    constructor(props) {
        super(props)
    }

    handleClick = () => {
        this.props.toggleExpandStory(this.props.payload)
    }

    render() {
        const {children} = this.props
        const {handleClick} = this

        return (
            <span style={{color: LINK_COLOR, cursor: 'pointer'}}
                  onClick={handleClick}
            >{children}</span>
        )
    }
}

class ButtonStoryCombo {
    buttonId
    storyId
    isToggledOpen = false

    constructor(buttonId, storyId) {
        this.buttonId = buttonId
        this.storyId = storyId
    }

    toggleStoryOpen = () => {
        this.isToggledOpen = !this.isToggledOpen
    }
}

export class ExpandingStory extends React.Component {
    props
    state = {
        buttonStoryCombos: []
    }

    static defaultProps = {}

    constructor(props) {
        super(props)
    }

    toggleExpandStory = (elementIndex) => {
        const buttonStoryCombos = [...this.state.buttonStoryCombos]

        buttonStoryCombos.forEach((buttonStoryCombo) => {
            if (buttonStoryCombo.buttonId === elementIndex) {
                buttonStoryCombo.toggleStoryOpen()
            }
        })

        this.setState({buttonStoryCombos: buttonStoryCombos})
    }

    storyIsExpanded = (elementIndex) => {
        let result = false
        this.state.buttonStoryCombos.forEach(buttonStoryCombo => {
            if (buttonStoryCombo.storyId === elementIndex && buttonStoryCombo.isToggledOpen) {
                result = true
            }
        })

        return result
    }

    componentDidMount() {
        let learnMoreButtonIds = []
        let expandingStoryIds = []

        this.props.children.forEach((child, index) => {
            if (React.isValidElement(child)) {
                // See gatsby-node.js for production issues with this code
                if (child.type.name === DetailedStory.name) {
                    expandingStoryIds.push(index)
                } else if (child.type.name === LearnMore.name) {
                    learnMoreButtonIds.push(index)
                }
            }
        })

        // Match the first `LearnMore` button to the first expanding story, the second to the second etc.
        // Aggressive minification makes passing a function as argument dangerous: buttonId is undefined
        const buttonStoryCombos = learnMoreButtonIds.map((buttonId, index) => {
            return new ButtonStoryCombo(buttonId, expandingStoryIds[index])
        })

        this.setState({
            buttonStoryCombos: buttonStoryCombos
        })
    }

    render() {
        const {children} = this.props
        const {toggleExpandStory, storyIsExpanded} = this

        const childrenConvertedIntoElements = children.map((child, index) => {
            if (typeof child === 'string') {
                return <span key={index}>{child}</span>
            }

            if (React.isValidElement(child)) {
                if (child.type.name === LearnMore.name) {
                    return <LearnMore key={index}
                                      payload={index}
                                      toggleExpandStory={toggleExpandStory}
                    >
                        {child.props.children}
                    </LearnMore>
                }

                if (child.type.name === DetailedStory.name && storyIsExpanded(index)) {
                    return <div key={index} className='appearing-from-the-top space-below'>
                        {child}
                    </div>
                }
            }
        })

        return (
            <div className="space-below">{childrenConvertedIntoElements}</div>
        )
    }
}
