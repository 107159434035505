import React from 'react'
import {graphql, Link} from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'
import {ServiceCarousel} from '../components/service-carousel'
import {ExpandingStory, LearnMore} from '../components/expanding-story'
import {DetailedStory} from '../components/detailed-story'
import '../pages/style.css'

class BlogIndex extends React.Component {
    render() {
        const {data} = this.props
        const siteTitle = data.site.siteMetadata.title
        const posts = data.allMarkdownRemark.edges

        return (
            <Layout location={this.props.location} title={siteTitle}>
                <SEO
                    title="All posts"
                    keywords={['interaction engineering', 'design', 'usability research', 'design research']}
                />
                <ServiceCarousel services={['interaction', 'services', 'context']}/>

                <div className="story">
                    <p>Are you looking for guidance on how to build digital products? I'm a senior digital product designer, working
                        from the beautiful city of Rotterdam, Holland. I'm always open to discuss new strategies or product ideas;
                        I might be able to collaborate in a project or help you hire designers or developers.</p> 
                    <p>I strive to be an 'auteur' kind of designer: next to my design education, I worked as a front-end software 
                        developer as well. In my practice now, I use this experience to write prototype code to quickly test 
                        ideas. After hours, I like to design and build mixed reality prototypes.</p>   
                    <h2>My specialization</h2>
                    <p><ul>
                        <li>UX design (9 years experience)</li>
                        <li>Front-end development (5 years experience)</li>
                        <li>Usability research</li>
                        <li>Design research</li>
                        <li>Concepting, prototyping</li>
                        <li>Building a front-end development or design capacity in an organization</li>
                    </ul></p>
                    {/* Security errors when running this code in the cloud */ }                  
                    {/* <ExpandingStory>
                        Want to know what <LearnMore> design </LearnMore> can do for your organization?
                        <DetailedStory>
                            <section>
                                <h2>When to hire a designer</h2>
                                <p>An designer investigates what people want and what products you could build to
                                    provide value for people. I use the designer's toolkit to help you to innovate.</p>
                                <p>Innovation happens in markets that aren't mature yet. The new market will be small
                                    and people won't even know what they're looking for. That's the right time to call a
                                    designer: to determine the job people want to do. And then to imagine products or
                                    services to do that job.</p>
                                <p>Try to hire a designer <strong>before</strong> you have a clear idea of what you want to build.</p>
                                <ExpandingStory>
                                    <LearnMore> How </LearnMore> do I do that, exactly?
                                    <DetailedStory>
                                        <section>
                                            <p>Early on in most projects, I advise to reduce waste by using a lean
                                                design method. I specialize in doing design research and sketching out
                                                new concepts quickly. Using actual software or a prototype, I validate
                                                the concepts with the people in the target audience.</p>
                                            <p>Depending on what your organization already knows, there are some design methods
                                                to choose from. Whether you'll need to pretotype, prototype or to build an actual product,
                                                I bring the right design methods to the table.</p>
                                        </section>
                                    </DetailedStory>
                                </ExpandingStory>
                            </section>
                        </DetailedStory>
                    </ExpandingStory> */}
                </div>


                <nav className={'footer'}>
                    {posts.map(({node}) => {
                        const title = node.frontmatter.title || node.fields.slug
                        return (
                            <h3 key={node.fields.slug}>
                                <Link className="link" to={node.fields.slug}>
                                    {title}
                                </Link>
                            </h3>
                        )
                    })}
                </nav>
            </Layout>
        )
    }
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
          }
        }
      }
    }
  }
`
